import * as Yup from "yup";

export type ForgotPasswordValues = {
  email: string;
};

export const initialValues = {
  email: "",
};

export const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});
