import React from "react";
import {
  Flex,
  FormikField,
  PrimaryButton,
  Box,
  UnderlineButton,
} from "flicket-ui";
import { Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";

import { getFormValues, validationSchema } from "~form/login";

type LoginFormProps = {
  formType: "magic" | "password";
  handleSubmit: (actions: any, values: any) => void;
  showForgotPassword?: boolean;
};

export const LoginForm = ({
  handleSubmit,
  formType,
  showForgotPassword = true,
}: LoginFormProps) => {
  return (
    <Formik
      initialValues={getFormValues({}, formType)}
      validationSchema={validationSchema(formType)}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, submitCount }) => (
        <Flex
          as="form"
          onSubmit={handleSubmit}
          maxWidth={{ default: "100%", sm: 380 }}
          flexDirection="column"
          role="form"
        >
          <FormikField
            label="E-mail"
            name="email"
            type="email"
            mb={3}
            submitCount={submitCount}
          />
          <AnimatePresence>
            {formType === "password" && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                style={{ width: "100%" }}
              >
                <FormikField
                  label="Password"
                  name="password"
                  type="password"
                  mb={4}
                  submitCount={submitCount}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <PrimaryButton
            type="submit"
            isLoading={isSubmitting}
            width="100%"
            lineHeight="normal"
            boxShadow="button"
          >
            Log-in
          </PrimaryButton>
          {showForgotPassword && (
            <Box mt="6/4" alignSelf="center">
              <UnderlineButton href="/forgot-password" color="N600">
                Forgot your password?
              </UnderlineButton>
            </Box>
          )}
        </Flex>
      )}
    </Formik>
  );
};
