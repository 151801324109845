import * as Yup from "yup";

import { passwordSchema } from "~form/shared";

export type LoginValues = {
  password: {
    email: string;
    password: string;
  };
  magic: {
    email: string;
  };
};

export const getFormValues = (
  { email, password }: Partial<LoginValues["password"]>,
  formType: "password" | "magic"
) =>
  ({
    password: {
      email: email ?? "",
      password: password ?? "",
    },
    magic: {
      email: email ?? "",
    },
  }[formType]);

export const validationSchema = (formType: "password" | "magic") =>
  ({
    password: Yup.object().shape({
      email: Yup.string().email().required(),
      password: passwordSchema,
    }),
    magic: Yup.object().shape({
      email: Yup.string().email().required(),
    }),
  }[formType]);
