import React from 'react';
import { Flex, FormikField, PrimaryButton } from 'flicket-ui';
import { Formik } from 'formik';

import { initialValues, validationSchema } from '~form/forgotPassword';

type ForgotPasswordFormProps = {
  handleSubmit(actions: any, values: any): void;
};

export const ForgotPasswordForm = ({
  handleSubmit,
}: ForgotPasswordFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, submitCount }) => (
        <Flex
          as="form"
          onSubmit={handleSubmit}
          maxWidth={{ default: '100%', sm: 380 }}
          flexDirection="column"
          mb={{ _: '1/2', md: 1 }}
          mt={3}
          role="form"
        >
          <FormikField
            label="E-mail"
            name="email"
            type="email"
            mb={3}
            autoFocus
            submitCount={submitCount}
          />
          <PrimaryButton
            type="submit"
            isLoading={isSubmitting}
            width="100%"
            lineHeight="normal"
            boxShadow="button"
          >
            Send email
          </PrimaryButton>
        </Flex>
      )}
    </Formik>
  );
};
