import * as Yup from "yup";

import { passwordSchema } from "~form/shared";

export type SignupValues = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  enableTwoFactorAuthentication: boolean;
};

export const getFormValues = ({
  email,
  password,
  firstName,
  lastName,
  enableTwoFactorAuthentication,
}: Partial<SignupValues>) => ({
  email: email ?? "",
  password: password ?? "",
  firstName: firstName ?? "",
  lastName: lastName ?? "",
  terms: false,
  enableTwoFactorAuthentication: enableTwoFactorAuthentication ?? false,
});

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  password: passwordSchema,
  terms: Yup.boolean().required("You need to accept terms and conditions"),
});
