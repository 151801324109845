import * as Yup from "yup";

const passwordValidationRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]).*$/;

export const passwordSchema = Yup.string()
  .matches(
    passwordValidationRegex,
    "Include at least one number, one uppercase letter and one special character"
  )
  .min(8, "Password needs to contain at least 8 characters")
  .required();

export const passwordRepeatSchema = Yup.string()
  .matches(
    passwordValidationRegex,
    "Include at least one number, one uppercase letter and one special character"
  )
  .min(8, "Password needs to contain at least 8 characters")
  .oneOf([Yup.ref("password")], "Passwords do not match")
  .required();
