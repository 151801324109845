import React, { ReactNode } from 'react';
import { Box } from 'flicket-ui';
import styled from 'styled-components';

type ContainerProps = {
  children: ReactNode;
};

const StyledContainer = styled(Box)`
  width: 100%;
  max-width: 368px;

  display: flex;
  flex-direction: column;

  margin-left: auto;
  margin-right: auto;
  padding: 24px;

  background: ${(p) => p.theme.colors.white};

  border-radius: 12px;
  box-shadow: ${(p) => p.theme.shadows.container};

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    margin-left: 16px;
    margin-right: 16px;

    width: calc(100% - 32px);

    padding: 24px 12px;
  }
`;

export const AuthContainer = ({ children }: ContainerProps) => (
  <StyledContainer>{children}</StyledContainer>
);
