import React, { ReactNode } from 'react';
import { Box, SystemProps } from 'flicket-ui';
import styled from 'styled-components';

const StyledContentContainer = styled(Box)`
  display: flex;
  flex: 1;

  background-color: ${(p) => p.theme.colors.N100};

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    height: 100%;

    display: flex;
    flex-direction: column;
  }
`;

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding-top: 80px;

  z-index: ${(p) => p.theme.zIndices.docked};

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    width: 100%;
    padding-top: 0;
  }
`;

interface ContentContainerProps extends SystemProps {
  children: ReactNode;
}

export const ContentContainer = ({
  children,
  ...props
}: ContentContainerProps) => {
  return (
    <StyledContentContainer {...props}>
      <Content>{children}</Content>
    </StyledContentContainer>
  );
};
