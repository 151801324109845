import * as Yup from "yup";

import { passwordSchema, passwordRepeatSchema } from "~form/shared";

export type SetPasswordValues = {
  password: string;
  passwordRepeat: string;
};

export const formValues = {
  password: "",
  passwordRepeat: "",
};

export const validationSchema = Yup.object().shape({
  password: passwordSchema,
  passwordRepeat: passwordRepeatSchema,
});
