import React from 'react';
import { Flex, FormikField, PrimaryButton } from 'flicket-ui';
import { Formik } from 'formik';

import { formValues, validationSchema } from '~form/setPassword';

type SetPasswordFormProps = {
  handleSubmit(actions: any, values: any): void;
};

export const SetPasswordForm = ({ handleSubmit }: SetPasswordFormProps) => {
  return (
    <Formik
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, submitCount }) => (
        <Flex
          as="form"
          onSubmit={handleSubmit}
          maxWidth={{ default: '100%', sm: 380 }}
          flexDirection="column"
          mb={{ _: '1/2', md: 1 }}
          mt={3}
          role="form"
        >
          <FormikField
            label="Password"
            name="password"
            type="password"
            mb={2}
            submitCount={submitCount}
          />
          <FormikField
            label="Repeat Password"
            name="passwordRepeat"
            type="password"
            mb={2}
            submitCount={submitCount}
          />
          <PrimaryButton
            type="submit"
            isLoading={isSubmitting}
            width="100%"
            lineHeight="normal"
            boxShadow="button"
          >
            Set password
          </PrimaryButton>
        </Flex>
      )}
    </Formik>
  );
};
